.checkboxInput + .checkboxControl:before {
  content: "";
  transition: opacity 0.1s ease-in-out;
  width: 0.5em;
  height: 0.5em;
  opacity: 0;
  background-color: #1f2937;
}

.checkboxInput:checked + .checkboxControl:before {
  opacity: 1;
  /* gray-800 */
}

:global(.dark) .checkboxInput + .checkboxControl:before {
  background-color: white;
}

.checkboxInput:focus + .checkboxControl {
  box-shadow: 0 0 0 0.05em #ddf, 0 0 0.15em 0.1em lightblue;
}
