@layer components {
  /* Inputs */
  .input-border {
    @apply border-gray-600 border rounded-sm;
  }

  .input {
    @apply bg-gray-800 text-white disabled:opacity-50;
    @apply input-border;
  }

  .input-error {
    @apply border-red-600 bg-red-900;
  }

  /* Buttons */
  .button {
    @apply bg-gray-800 px-2 py-1 rounded-sm text-white;
  }

  .button-sm {
    @apply button;
    @apply text-sm;
  }

  .button-xs {
    @apply button;
    @apply text-xs;
  }
}

/* below our custom component styles so we can override if we want to. */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    margin: 0;
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont,
      "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .pxn-fc-widget-customisation {
    @apply transition-transform;
  }
}

.mainGrid {
  grid-template-areas: "preview tools";
  grid-template-columns: 2fr minmax(auto, 240px);
  grid-template-rows: 1fr;
}

.tools {
  grid-area: tools;
}

.header {
  grid-area: header;
}

.preview {
  grid-area: preview;
}

.zoom-slider {
  line-height: 0.8;
}

/* Modal darkmode  - these need to be important because
*  react modal uses some inline styles.... */
.dark .ReactModal__Content {
  @apply border-gray-600 bg-gray-900 !important;
}
.dark .ReactModal__Overlay {
  @apply bg-gray-900 bg-opacity-90 !important;
}
