.radioInput:checked + .radioControl {
  background: radial-gradient(#1f2937 50%, rgba(255, 0, 0, 0) 51%);
}

:global(.dark) .radioInput:checked + .radioControl {
  background: radial-gradient(white 50%, rgba(255, 0, 0, 0) 51%);
}

.radioInput:focus + .radioControl {
  box-shadow: 0 0 0 0.05em #ddf, 0 0 0.15em 0.1em lightblue;
}
